




@import 'style';


.rx-root-page-eula-1 {
    max-width: 650rem;
    background-color: $white;
    color: $gray-90;
    padding: 24rem 0;
    margin: 24rem 0;
    p, h1, h2, ol {
        padding: 0 24rem;
    }
    h1 {
        @include headline-4;
    }
    h2{
        @include headline-5;
        margin-top: 24rem;
        &:before {
            content: counter(section) ". "
        }
    }
    .section {
        counter-increment: section;
    }
    p {
        margin: 16rem 0;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
        @include text-body;
        .definition {
            font-weight: bold;
        }
    }
    .conspicious {
        font-size: 18rem;
        p, li {
            font-size: 18rem;
        }
        border-color: $red-50;
        border-style: solid;
        border-width: 0 4rem;
        background-color: fade-out($red-10, .8);
        padding: 24rem 28rem;
        margin: 16rem 0;
    }
    ol {
        margin: 16rem 0;
        @include text-body;
        counter-reset: section;

        ol {
            margin-left: 16rem;
        }
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
        li {
            margin: 16rem 0;
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
            list-style-type: none;
            counter-increment: section;
            &:before {
                font-weight: bold;
                content: "(" counters(section, ".") ") "
            }
        }
    }
    h1, h2 {
        & + p {
            margin-top: 0;
        }
    }

}