










@import 'style.scss';


.rx-common-header-1 {
    height: 100%;
    width: auto;
}