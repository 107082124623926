







@import 'style.scss';


.rx-root-terms-page-1 {
    @include navigation-link;
    transition: all 150ms;
    border-radius: 4rem;
    height: 36rem;
    padding: 0 6rem;
    display: grid;
    grid: 1fr / auto auto;
    grid-gap: 8rem;
    align-items: center;
    justify-content: start;
    svg {
        color: $gray-60;
    }
    &.active svg {
        color: $white;
    }
}

.rx-root-terms-page-2 {
    font-size: 24rem;
}

.rx-root-terms-page-3 {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-gap: 12rem;
    background-color: $white;
    height: max-content;
    padding: 24rem;
    border-radius: 4rem;

    @include mobile {
        grid-row: 1;
    }
}

.rx-root-terms-page-4 {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: $white;
    border-radius: 4rem;
}