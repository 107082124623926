










@import 'style.scss';


.rx-root-app-header-1 {
    position: relative;
}

.rx-root-app-header-2 {
    color: $white;
    display: flex;
    align-items: center;
    @include text-body;
    font-size: 16px;
    &:hover {
        svg {
            color: $white;
        }
    }
    @media print {
        color: black;
    }
}

.rx-root-app-header-3 {
    font-size: 20px;
    color: white;
    margin-left: 1px;
    transition-property: color;
    transition-duration: 150ms;
    @media print {
        display: none;
    }
}

.rx-root-app-header-4 {
    background-color: $white;
    border-radius: 6px;
    @include shadow-8;
    position: absolute;
    z-index: 100;
    bottom: 0;
    left: calc(100% + 8px);
    padding: 12px 0px;
    width: 240px;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 150ms;
    box-sizing: border-box;
    pointer-events: none;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    &.show {
        pointer-events: auto;
        opacity:1;
    }
    @include mobile-tiny {
        left: 20px;
    }
}

.rx-root-app-header-5 {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 99;
}











































.rx-root-app-header-6 {
    @include button;

    height: 36px;
    font-size: 16px;
    @include not-mobile-tiny {
        display: none;
    }
    @media print {
        display: none;
    }
    background-color: transparent;
    border-color: transparent;
    color: $white;
    font-size: 32px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    &:hover {
        background-color: $gray-60;
    }
}

.rx-root-app-header-7 {
    margin: 0 12px;
    padding: 4px 12px;
    flex: 0 0 auto;
    color: $gray-90;
    @include text-body-link;
    font-size: 16px;
    &:hover {
        background: $gray-30;
    }
    border-radius: 6px;
}

.rx-root-app-header-8 {
    position: absolute;
    top: 0;
    height: 100%;
    border-left: 4rem solid $green-50;
    left: 100%;
    z-index: 13;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
        transform-origin: center left;
        transform: rotate(-90deg);
        background-color: $green-50;
        color: $white;
        @include text-label-bold;
        font-size: 12.8rem;
        padding: 0 8rem 2rem 8rem;
        border-radius: 0 0 3rem 3rem;
        margin-top: -3rem;
        white-space: nowrap;
        position: relative;
    }
}

.rx-root-app-header-9 {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
}